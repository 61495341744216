<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardClass" tile flat >
        <v-tabs v-model="activeTab.event" grow light show-arrows="mobile">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab class="pl-1 pr-1 rounded-lg"><v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">mdi-alert</v-icon><span class="hidden-sm-and-down">Alertes</span></v-tab>
            <v-tab class="pl-1 pr-1 rounded-lg"><v-icon class="mr-1" :color="(activeTab.event == 1) ? 'primary' : 'grey'">mdi-file</v-icon><span class="hidden-sm-and-down">Dernière taches</span></v-tab>
        </v-tabs>
        <v-card-text class="pa-0">
            <v-list dense class="pb-3" v-if="alerts.length" v-show="activeTab.event === 0">
                <template v-for="(item, index) in alerts">
                    <v-list-item :key="index" class="pb-0" :href="'/#/map/'">
                        <v-list-item-content>
                            <v-list-item-title :style="'color:' + item.sta_color + ';'">
                                <v-icon>mdi-alert-box-outline</v-icon> <span class="caption grey--text">{{ item.eve_code }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption font-italic">{{ item.eve_custom_fields.eve_description }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mt-1 mb-1">
                            <span class="caption">{{ $date(item.eve_date_start).format('YYYY-MM-DD') }}</span>
                            <v-chip small label outlined>{{ typeState[item.eve_typ_id.substring(0,3)][item.eve_typ_id].typ_name }}</v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="'D-' + index"></v-divider>
                </template>
            </v-list>
            <v-alert v-else v-show="activeTab.event === 0" color="warning" class="ma-2" icon="mdi-alert">Aucune alerte</v-alert>
            <v-list dense class="pb-3" two-line v-if="tasks.length" v-show="activeTab.event === 1">
                <template v-for="(item, index) in tasks">
                    <v-list-item :key="index" class="pb-0" :href="'/#/map'">
                        <v-list-item-content>
                            <v-list-item-title :style="'color:' + item.tsk_sta_id + ';'">
                                <v-icon>mdi-traffic-cone</v-icon> <span class="caption grey--text">{{ item.tty_name }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption font-italic">temps: {{ item.tsk_duration }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mt-1 mb-1">
                            <span class="caption">{{ $date(item.tsk_date_plan).format('YYYY-MM-DD') }}</span>
                            <v-chip small label outlined :color="getCacheType('TSK', item.tsk_typ_id).typ_color">
                                {{ getCacheType('TSK', item.tsk_typ_id).typ_name }}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="'D-' + index"></v-divider>
                </template>
            </v-list>
            <v-alert v-else v-show="activeTab.event === 1" color="info" class="ma-2" icon="mdi-alert">Aucune tache</v-alert>
        </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardClass" tile flat >
        <v-tabs v-model="activeTab.contact" grow light show-arrows="mobile">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab class="pl-1 pr-1 rounded-lg"><v-icon class="mr-1" :color="(activeTab.contact == 0) ? 'primary'  : 'grey'">mdi-account-alert-outline</v-icon><span class="hidden-sm-and-down">Urgences</span></v-tab>
            <v-tab class="pl-1 pr-1 rounded-lg"><v-icon class="mr-1" :color="(activeTab.contact == 1) ? 'primary'  : 'grey'">mdi-account-multiple-outline</v-icon><span class="hidden-sm-and-down">Contacts</span></v-tab>
        </v-tabs>
        <v-list class="pb-0" v-if="contacts.length" v-show="activeTab.contact === 0" three-line>
            <template  v-for="(item, index) in contacts">
                <v-list-item :key="index" class="pb-0" v-if="item.ctt_typ_id == 'CTT04'">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.ctt_firstname }} {{ item.ctt_lastname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.ctt_customfields['ctt_description'] }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span class="caption mr-2">{{ item.ctt_mobile }}</span>
                        <v-chip small outlined label light :color="'light-green'">{{ item.ctt_email }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else v-show="activeTab.contact === 0" color="info" class="ma-2" icon="mdi-alert">Aucun Contact</v-alert>
        <v-list class="pb-0" v-if="contacts.length" v-show="activeTab.contact === 1" three-line>
            <template  v-for="(item, index) in contacts">
                <v-list-item :key="index" class="pb-0" v-if="item.ctt_typ_id == 'CTT01'">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.ctt_firstname }} {{ item.ctt_lastname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.ctt_customfields['ctt_description'] }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span><v-icon small class="mr-2">mdi-phone</v-icon><a :href="item.ctt_mobile" class="caption mr-2">{{ item.ctt_mobile }}</a></span>
                        <v-chip small outlined label light :color="'light-green'">{{ item.ctt_email }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else v-show="activeTab.contact === 1" color="info" class="ma-2" icon="mdi-alert">Aucun Contact</v-alert>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardClass" tile flat>
            <v-toolbar flat :color="uiParams.cardToolbarColor" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Répartition par état</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <apexchart v-if="chartView.area" width="100%" height=440 type="pie" :options="chartOptions" :series="series"></apexchart>
                <v-alert v-else color="info" class="mt-2" icon="mdi-alert">Aucune donnée</v-alert>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>

</v-container>
</template>

<script>
import { cacheGetters } from "@/store/cache.module"
import { usrGetters }   from "@/store/user.module"
import VueApexCharts from 'vue-apexcharts'
import apexLocale  from '@/plugins/locales.js'
import { tskObj }       from '@/mixins/tsk_obj.js'

export default {
    name: 'dashboard',
    props: ['toast', 'filterView'],
    mixins: [ tskObj ],
    data () {
        return {
            uiParams: {
                cardClass: 'elevation-0  rounded-lg pb-1',
                cardToolbarColor: '#FAFAFA'
            },
            activeTab: { event: 0, contact: 0 }, showTab: true,
            loading: { getItem: true, getRessource: false },
            isAuthorized: usrGetters.getScopeRight,
            saveProgress: false,
            dialog: false,
            paginevent: { totalItems: 0 },
            pagindoc  : { totalItems: 0 },
            paginusers: { totalItems: 0 },
            paginalerts: { totalItems: 0 },
            events: [],  tasks: [], alerts: [], info : [], documents: [], users: [], contacts: [],
            infoContent: {},
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            alertSwitch: 50,
            chartView: { area: false },
            series: [],
            chartOptions: {
                chart: {
                    toolbar: { show: true, tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true } },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: { chart: { width: 200 }, legend: { position: 'bottom' } }
                }],
                plotOptions: {
                    pie: { dataLabels: { offset: 0, minAngleToShowLabel: 0 }, }
                }
            },
            stateState: cacheGetters.getStates(),
            cacheType: cacheGetters.getTypes(),
            typeState: cacheGetters.getTypes(),
        }
    },
    mounted:function (){
        this.getContact()
        this.getEvent()
        this.getTaskIndex(false, false, '?page=1&per_page=4&order_by=tsk_id&order_direction=desc&extended=withtasktype').then( () => {
                this.tasks = this.listTask
        })           
        //this.getTaskGraph()
    },
    methods:{
        getEvent(){
            let criteria = encodeURI('eve_sta_id=TSK01&eve_typ_id=MEV04')
            this.$http.get( '/events/?' + criteria + '&page=1&per_page=4&order_by=eve_id&order_direction=desc' ).then( (response) => {
                this.alerts = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        getContact(){
            let criteria = encodeURI('ctt_sta_id=GEN01')
            this.$http.get( '/contacts/?' + criteria + '&page=1&per_page=5' ).then( (response) => {
                this.contacts = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        //testEndpoint(){
        //    this.$http.get( '/trees' ).then( (response) => { this.items = response.data.data })
        //},
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
</style>